export const LOGIN = 'LOGIN'
export const CHECK_EXPIRED_JWT = 'CHECK_EXPIRED_JWT'
export const LOGOUT = 'LOGOUT'
export const SIGNUP = 'SIGNUP'
export const CLEAR_ALL = 'CLEAR_ALL'

// User
export const FETCH_USERS = 'FETCH_USERS'
export const CHECK_USER_INFO = 'CHECK_USER_INFO'
export const CHECK_EMAIL_INFO = 'CHECK_EMAIL_INFO'
export const CHECK_SIGNUP_PROHIBITS_INFO = 'CHECK_SIGNUP_PROHIBITS_INFO'
export const SIGNUP_ONE = 'SIGNUP_ONE'
