import gql from 'graphql-tag'
import { apolloClient } from '@/vue-apollo'
import {
  FETCH_SET_IP_BLOCK,
  FETCH_SET_DEPOSIT_EXCHANGE,
  FETCH_GAME_SET_MENU,
  FETCH_GAME_SET_ITEM,
  FETCH_GAME_SET_ODDS_CUSTOM,
  FETCH_GAME_SET_COMBINATION,
  FETCH_SET_EVENT,
  FETCH_GAME_SET_VENDOR,
  FETCH_GAME_SLOT_VENDOR,
  FETCH_GAME_SLOT_LIST,
  FETCH_GAME_SLOT_START,
  FETCH_GAME_CARD_LIST,
  FETCH_GAME_CARD_START,
  FETCH_SET_SIGNUP,
} from '@/store/settings/action'
import {
  UPSERT_REQUEST_ALARMS,
} from '@/store/settings/mutation'

const settingsStore = {
  namespaced: true,
  state: {
    fetchSetIpBlock: [],
    upsertRequestAlarms: [],
    fetchSetDepositExchange: [],
    fetchGameSetMenu: [],
    fetchGameSetItem: [],
    fetchGameSetOddsCustom: [],
    fetchGameSetCombination: [],
    fetchSetEvent: [],
    fetchGameSetVendor: [],
    fetchGameSlotVendor: [],
    fetchGameSlotList: [],
    fetchGameSlotStart: [],
    fetchGameCardList: [],
    fetchGameCardStart: [],
    fetchSetSignup: [],
  },
  getters: {
    // getField,
    fetchSetIpBlock: state => state.fetchSetIpBlock,
    upsertRequestAlarms: state => state.upsertRequestAlarms,
    fetchSetDepositExchange: state => state.fetchSetDepositExchange[0],
    fetchGameSetMenu: state => state.fetchGameSetMenu,
    fetchGameSetItem: state => state.fetchGameSetItem,
    fetchGameSetOddsCustom: state => state.fetchGameSetOddsCustom,
    fetchGameSetCombination: state => state.fetchGameSetCombination,
    fetchSetEvent: state => state.fetchSetEvent,
    fetchGameSetVendor: state => state.fetchGameSetVendor,
    fetchGameSlotVendor: state => state.fetchGameSlotVendor,
    fetchGameSlotList: state => state.fetchGameSlotList,
    fetchGameSlotStart: state => state.fetchGameSlotStart,
    fetchGameCardList: state => state.fetchGameCardList,
    fetchGameCardStart: state => state.fetchGameCardStart,
    fetchSetSignup: state => state.fetchSetSignup[0],
  },
  mutations: {
    // updateField,
    [FETCH_SET_IP_BLOCK](state, fetchSetIpBlock) {
      state.fetchSetIpBlock = fetchSetIpBlock
    },
    [UPSERT_REQUEST_ALARMS](state, upsertRequestAlarms) {
      state.upsertRequestAlarms = upsertRequestAlarms
    },
    [FETCH_SET_DEPOSIT_EXCHANGE](state, fetchSetDepositExchange) {
      state.fetchSetDepositExchange = fetchSetDepositExchange
    },
    [FETCH_GAME_SET_MENU](state, fetchGameSetMenu) {
      state.fetchGameSetMenu = fetchGameSetMenu
    },
    [FETCH_GAME_SET_ITEM](state, fetchGameSetItem) {
      state.fetchGameSetItem = fetchGameSetItem
    },
    [FETCH_GAME_SET_ODDS_CUSTOM](state, fetchGameSetOddsCustom) {
      state.fetchGameSetOddsCustom = fetchGameSetOddsCustom
    },
    [FETCH_GAME_SET_COMBINATION](state, fetchGameSetCombination) {
      state.fetchGameSetCombination = fetchGameSetCombination
    },
    [FETCH_SET_EVENT](state, fetchSetEvent) {
      state.fetchSetEvent = fetchSetEvent
    },
    [FETCH_GAME_SET_VENDOR](state, fetchGameSetVendor) {
      state.fetchGameSetVendor = fetchGameSetVendor
    },
    [FETCH_GAME_SLOT_VENDOR](state, fetchGameSlotVendor) {
      state.fetchGameSlotVendor = fetchGameSlotVendor
    },
    [FETCH_GAME_SLOT_LIST](state, fetchGameSlotList) {
      state.fetchGameSlotList = fetchGameSlotList
    },
    [FETCH_GAME_SLOT_START](state, fetchGameSlotStart) {
      state.fetchGameSlotStart = fetchGameSlotStart
    },
    [FETCH_GAME_CARD_LIST](state, fetchGameCardList) {
      state.fetchGameCardList = fetchGameCardList
    },
    [FETCH_GAME_CARD_START](state, fetchGameCardStart) {
      state.fetchGameCardStart = fetchGameCardStart
    },
    [FETCH_SET_SIGNUP](state, fetchSetSignUp) {
      state.fetchSetSignup = fetchSetSignUp
    },
  },
  actions: {
    async [FETCH_SET_IP_BLOCK]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
                    query fetchSetIpBlock($site: String) {
                        fetchSetIpBlock(site: $site) {
                            idx
                            site
                            ip
                            type
                            memo
                            enrollId
                            createdAt
                            updatedAt
                        }
                    }
                `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_IP_BLOCK, data.fetchSetIpBlock)
      console.log('FETCH_SET_IP_BLOCK : ', data.fetchSetIpBlock)
    },
    async [UPSERT_REQUEST_ALARMS]({ commit, state }, payload) {
      console.log('update payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
                  mutation upsertRequestAlarms($idx: Float, $site: String, $category: String, $title: String, $content: String, $requestId: String, $read: String, $readId: String, $readAt: String, $confirm: String, $confirmId: String, $confirmAt: String, $result: String, $uid: String){
                      upsertRequestAlarms(idx: $idx, site: $site, category: $category, title: $title, content: $content, requestId: $requestId, read: $read, readId: $readId, readAt: $readAt, confirm: $confirm, confirmId: $confirmId, confirmAt: $confirmAt, result: $result, uid: $uid) {
                          idx
                          site
                          category
                          title
                          content
                          requestId
                          read
                          readId
                          readAt
                          confirm
                          confirmId
                          confirmAt
                          result
                          uid
                      }
                  }
              `,
        variables: {
          idx: payload.idx,
          site: payload.site,
          category: payload.category,
          title: payload.title,
          content: payload.content,
          requestId: payload.requestId,
          read: payload.read,
          readId: payload.readId,
          readAt: payload.readAt,
          confirm: payload.confirm,
          confirmId: payload.confirmId,
          confirmAt: payload.confirmAt,
          result: payload.result,
          uid: payload.uid,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPSERT_REQUEST_ALARMS, data.upsertRequestAlarms)
      console.log('UPSERT_REQUEST_ALARMS : ', data.upsertRequestAlarms)
    },
    async [FETCH_SET_DEPOSIT_EXCHANGE]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchSetDepositExchange($site: String) {
            fetchSetDepositExchange(site: $site) {
              idx
              site
              depositMin
              depositAmount
              depositInterval
              depositNotice
              exchangeMin
              exchangeMax
              exchangeAmount
              exchangeInterval
              exchangeNotice
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_DEPOSIT_EXCHANGE, data.fetchSetDepositExchange)
      console.log('FETCH_SET_DEPOSIT_EXCHANGE : ', data.fetchSetDepositExchange)
    },
    async [FETCH_GAME_SET_MENU]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
              query fetchGameSetMenu($site: String) {
                    fetchGameSetMenu(site: $site) {
                        idx
                        site
                        type
                        id
                        name
                        nameCustom
                        minBet
                        maxBet
                        maxWin
                        foldFrom
                        foldTo
                        useYn
                        menuUseYn
                        createdAt
                        updatedAt
                    }
                }
            `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_SET_MENU, data.fetchGameSetMenu)
      console.log('FETCH_GAME_SET_MENU : ', data.fetchGameSetMenu)
    },
    async [FETCH_GAME_SET_ITEM]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
                  query fetchGameSetItem($site: String) {
                      fetchGameSetItem(site: $site) {
                          idx
                          site
                          type
                          menuId
                          menuName
                          id
                          name
                          nameCustom
                          useYn
                          createdAt
                          updatedAt
                      }
                  }
              `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_SET_ITEM, data.fetchGameSetItem)
      console.log('FETCH_GAME_SET_ITEM : ', data.fetchGameSetItem)
    },
    async [FETCH_GAME_SET_ODDS_CUSTOM]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
                  query fetchGameSetOddsCustom($site: String) {
                      fetchGameSetOddsCustom(site: $site) {
                          idx
                          site
                          type
                          menuId
                          menuName
                          itemId
                          itemName
                          oddsType
                          oddsTypeName
                          oddsAddHome
                          oddsAddDraw
                          oddsAddAway
                          oddsAddStraight
                          oddsAddUnderDog
                          oddsMinInvisible
                          createdAt
                          updatedAt
                      }
                  }
              `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_SET_ODDS_CUSTOM, data.fetchGameSetOddsCustom)
      console.log('FETCH_GAME_SET_ODDS_CUSTOM : ', data.fetchGameSetOddsCustom)
    },
    async [FETCH_GAME_SET_COMBINATION]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchGameSetCombination($gameSetCombinationInputArgs: GameSetCombinationInputArgs!) {
            fetchGameSetCombination(gameSetCombinationInputArgs: $gameSetCombinationInputArgs) {
              idx
              site
              menuCode
              sportCode
              combination
              alertMessage
              allow
              useYn
              updatedAt
              createdAt
            }
          }
        `,
        variables: {
          gameSetCombinationInputArgs: {
            site: payload.site,
            menuCode: payload.menuName,
            sportCode: payload.sports,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_SET_COMBINATION, data.fetchGameSetCombination)
      console.log('FETCH_GAME_COMBINATION : ', data.fetchGameSetCombination)
    },
    async [FETCH_SET_EVENT]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
                query fetchSetEvent($site: String) {
                    fetchSetEvent(site: $site) {
                         idx
                         site
                         gameName
                         eventName
                         minFolds
                         bonusOdds
                         active
                         createdAt
                         updatedAt
                    }
                }
            `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_EVENT, data.fetchSetEvent)
      console.log('FETCH_SET_EVENT : ', data.fetchSetEvent)
    },
    // 벤더 조회
    async [FETCH_GAME_SET_VENDOR]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
                  query fetchGameSetVendor($site: String, $category: String, $useYn: String) {
                      fetchGameSetVendor(site: $site, category: $category, useYn: $useYn) {
                          idx
                          site
                          category
                          vendorName
                          vendorNameCustom
                          useYn
                          createdAt
                          updatedAt
                      }
                  }
              `,
        variables: {
          site: payload.site,
          category: payload.category,
          useYn: payload.useYn,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_SET_VENDOR, data.fetchGameSetVendor)
      console.log('FETCH_GAME_SET_VENDOR : ', data.fetchGameSetVendor)
    },
    // 카지노 슬롯 벤더 리스트
    async [FETCH_GAME_SLOT_VENDOR]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
            query fetchGameSlotVendor($site: String) {
                fetchGameSlotVendor(site: $site) {
                    idx
                    site
                    category
                    vendorName
                    vendorNameCustom
                    useYn
                    createdAt
                    updatedAt
                }
            }
        `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_SLOT_VENDOR, data.fetchGameSlotVendor)
      console.log('FETCH_GAME_SLOT_VENDOR : ', data.fetchGameSlotVendor)
    },
    // 카지노 슬롯 게임 리스트
    async [FETCH_GAME_SLOT_LIST]({ commit }, payload) {
      // console.log('payload:::', payload.vendor)
      const { data } = await apolloClient.query({
        query: gql`
            query fetchGameSlotList($vendor: String) {
              fetchGameSlotList(vendor: $vendor) {
                title
                type
                id
                vendor
                provider
                thumbnail
                thumbnails
                rank
                langs
              }
            }
          `,
        variables: {
          vendor: payload.vendor,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_SLOT_LIST, data.fetchGameSlotList)
      console.log('FETCH_GAME_SLOT_LIST : ', data.fetchGameSlotList)
    },
    // 카지노 슬롯 게임 스타트
    async [FETCH_GAME_SLOT_START]({ commit }, payload) {
      // console.log('payload:::', payload)
      const { data } = await apolloClient.query({
        query: gql`
                  query fetchGameSlotStart($username: String, $nickname: String, $game_id: String, $vendor: String) {
                      fetchGameSlotStart(username: $username, nickname: $nickname, game_id: $game_id, vendor: $vendor) {
                          link
                      }
                  }
              `,
        variables: {
          username: payload.username,
          nickname: payload.nickname,
          game_id: payload.game_id,
          vendor: payload.vendor,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_SLOT_START, data.fetchGameSlotStart)
      console.log('FETCH_GAME_SLOT_START : ', data.fetchGameSlotStart)
    },
    // 카지노 카드 게임 리스트
    async [FETCH_GAME_CARD_LIST]({ commit }, payload) {
      // console.log('payload:::', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchGameCardList($site: String) {
            fetchGameCardList(site: $site) {
              title
              type
              id
              provider
              thumbnail
              thumbnails
              vendor
            }
          }
        `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_CARD_LIST, data.fetchGameCardList)
      console.log('FETCH_GAME_CARD_LIST : ', data.fetchGameCardList)
    },
    // 카지노 카드 게임 스타트
    async [FETCH_GAME_CARD_START]({ commit }, payload) {
      // console.log('payload:::', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchGameCardStart($username: String, $nickname: String, $game_id: String, $vendor: String) {
            fetchGameCardStart(username: $username, nickname: $nickname, game_id: $game_id, vendor: $vendor) {
              link
            }
          }
        `,
        variables: {
          username: payload.username,
          nickname: payload.nickname,
          game_id: payload.game_id,
          vendor: payload.vendor,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_CARD_START, data.fetchGameCardStart)
      console.log('FETCH_GAME_CARD_START : ', data.fetchGameCardStart)
    },
    async [FETCH_SET_SIGNUP]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchSetSignup($site: String) {
            fetchSetSignup(site: $site) {
              signupType
            }
          }
        `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_SIGNUP, data.fetchSetSignup)
      console.log('FETCH_SET_SIGNUP : ', data.fetchSetSignup)
    },
  },
}

export default settingsStore
