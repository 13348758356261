import gql from 'graphql-tag'
import { apolloClient } from '@/vue-apollo'
import { INSERT_USER_SIGNIN_LOGS } from '@/store/logs/mutation'
import {
  FETCH_SIGNIN_LOGS,
  FETCH_LOGS_USERS_DETAIL_POINT,
  SIGNIN_LOGS,
} from '@/store/logs/action'

const logStore = {
  namespaced: true,
  state: () => ({
    userSigninLogs: [],
    fetchLogsUsersDetailPoint: [],
    fetchSigninLogs: [],
  }),
  getters: {
    userSigninLogs: state => state.userSigninLogs,
    fetchLogsUsersDetailPoint: state => state.fetchLogsUsersDetailPoint,
    fetchSigninLogsItems: state => state.fetchSigninLogs,
  },
  mutations: {
    [INSERT_USER_SIGNIN_LOGS](state, userSigninLogs) {
      // state.userSigninLogs.push(userSigninLogs)
      state.userSigninLogs = userSigninLogs
    },
    [FETCH_LOGS_USERS_DETAIL_POINT](state, fetchLogsUsersDetailPoint) {
      state.fetchLogsUsersDetailPoint = fetchLogsUsersDetailPoint
    },
    [FETCH_SIGNIN_LOGS](state, fetchSigninLogs) {
      state.fetchSigninLogs = fetchSigninLogs
    },
  },
  actions: {
    async [SIGNIN_LOGS]({ commit }, payload) {
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation insertUserSigninLogs($site: String, $userid: String, $ip: String, $browser: String, $browserVer: String, $os: String, $osVer: String, $platform: String, $networkCo: String, $networkType: String, $asn: String, $route: String, $flag: String, $country: String, $city: String, $latitude: String, $longitude: String){
            insertUserSigninLogs(site: $site, userid: $userid, ip: $ip, browser: $browser, browserVer: $browserVer, os: $os, osVer: $osVer, platform: $platform, networkCo: $networkCo, networkType: $networkType, asn: $asn, route: $route, flag: $flag, country: $country, city: $city, latitude: $latitude, longitude: $longitude){
              site,
              userid,
              ip,
              browser,
              browserVer,
              os,
              osVer,
              platform,
              networkCo,
              networkType,
              asn,
              route,
              flag,
              country,
              city,
              latitude,
              longitude,
            }
          }
        `,
        variables: {
          site: payload.site,
          userid: payload.userid,
          ip: payload.ip,
          browser: payload.browser,
          browserVer: payload.browserVer,
          os: payload.os,
          osVer: payload.osVer,
          platform: payload.platform,
          networkCo: payload.networkCo,
          networkType: payload.networkType,
          asn: payload.asn,
          route: payload.route,
          flag: payload.flag,
          country: payload.country,
          city: payload.city,
          latitude: payload.latitude,
          longitude: payload.longitude,
        },
      })
      commit(INSERT_USER_SIGNIN_LOGS, data)
    },
    // 유저 point 변경 로그(유저 상세)
    async [FETCH_LOGS_USERS_DETAIL_POINT]({ commit }, payload) {
      console.log(payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchLogsUsersDetailPoint($baseDate: String, $site: String, $periodFrom: String, $periodTo: String, $userid: String, $titleSub: String, $categorySub: String) {
            fetchLogsUsersDetailPoint(baseDate: $baseDate, site: $site, periodFrom: $periodFrom, periodTo: $periodTo, userid: $userid, titleSub: $titleSub, categorySub: $categorySub) {
              baseDate
              site
              title
              titleSub
              category
              categorySub
              userid
              asisContent
              tobeContent
              changeContent
              memo
              systemType
              systemTypeDetail
              performer
              adminId
              adminIp
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
          userid: payload.userid,
          titleSub: payload.titleSub,
          categorySub: payload.categorySub,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_LOGS_USERS_DETAIL_POINT, data.fetchLogsUsersDetailPoint)
      console.log('FETCH_LOGS_USERS_DETAIL_POINT : ', data.fetchLogsUsersDetailPoint)
    },
    async [FETCH_SIGNIN_LOGS]({ commit }) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchUserSigninLogs {
            fetchUserSigninLogs {
              id
              site
              userid
              ip
              browser
              browserVer
              os
              osVer
              platform
              networkCo
              networkType
              asn
              route
              country
              city
              latitude
              longitude
              createAt
            }
          }
        `,
        variables: {
        },
        fetchPolicy: 'no-cache',
      })
      // console.log(data)
      commit(FETCH_SIGNIN_LOGS, data.fetchUserSigninLogs)
    },
  },
}

export default logStore
