import gql from 'graphql-tag'
import { apolloClient } from '@/vue-apollo'
import {
  FETCH_BOARD,
  FETCH_FAQ,
  FETCH_POPUP,
  FETCH_SET_BASIC,
} from '@/store/notice/action'

const noticeStore = {
  namespaced: true,
  state: {
    fetchBoard: [],
    fetchFaq: [],
    fetchPopup: [],
    fetchSetBasic: [],
  },
  getters: {
    fetchBoard: state => state.fetchBoard,
    fetchFaq: state => state.fetchFaq,
    fetchPopup: state => state.fetchPopup,
    fetchSetBasic: state => state.fetchSetBasic,
  },
  mutations: {
    [FETCH_BOARD](state, fetchBoard) { state.fetchBoard = fetchBoard },
    [FETCH_FAQ](state, fetchFaq) { state.fetchFaq = fetchFaq },
    [FETCH_POPUP](state, fetchPopup) {
      // 공지사항 제외 건 추출
      const popup = document.cookie.split(';')
        .map(cookie => cookie.trim().split('=')[0]) // 쿠키 문자열에서 쿠키 이름만 추출
        .filter(name => name.startsWith('po')) // "PO"로 시작하는 이름 필터링
        .map(name => parseInt(name.substring(2), 10)) // "PO" 접두사 제거

      state.fetchPopup = fetchPopup.filter(item => !popup.includes(Number(item.idx)))
    },
    [FETCH_SET_BASIC](state, fetchSetBasic) { state.fetchSetBasic = fetchSetBasic },
  },
  actions: {
    async [FETCH_BOARD]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
                    query fetchBoard($site: String) {
                        fetchBoard(site: $site) {
                            idx
                            site
                            title
                            content
                            enrollId
                            createdAt
                            updatedAt
                        }
                    }
                `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_BOARD, data.fetchBoard)
      console.log('FETCH_BOARD : ', data.fetchBoard)
    },
    async [FETCH_FAQ]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
                    query fetchSetNoticeFaq($site: String) {
                        fetchSetNoticeFaq(site: $site) {
                            idx
                            site
                            category
                            title
                            content
                            enrollId
                            createdAt
                            updatedAt
                        }
                    }
                `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_FAQ, data.fetchSetNoticeFaq)
      console.log('FETCH_FAQ : ', data.fetchSetNoticeFaq)
    },
    async [FETCH_POPUP]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchPopup($site: String) {
            fetchPopup(site: $site) {
              idx
              site
              title
              activeFrom
              activeTo
              active
              content
              enrollId
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })

      commit(FETCH_POPUP, data.fetchPopup)
      console.log('FETCH_POPUP : ', data.fetchPopup.length)
    },
    async [FETCH_SET_BASIC]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
                  query fetchSetBasic($site: String) {
                      fetchSetBasic(site: $site) {
                          idx
                          site
                          siteStatus
                          mainNotice
                          maintenanceNotice
                      }
                  }
              `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_BASIC, data.fetchSetBasic)
      console.log('FETCH_SET_BASIC : ', data.fetchSetBasic)
    },
  },
}

export default noticeStore
