import gql from 'graphql-tag'
import { apolloClient } from '@/vue-apollo'
import { FETCH_TOP_MENU_NOTIFICATION } from '@/store/notification/action'
import { UPDATE_TOP_MENU_NOTIFICATION } from '@/store/notification/mutation'

// export default {
const notificationStore = {
  namespaced: true,
  state: {
    notifications: [],
    fetchTopMenuNotification: [],
    updateTopMenuNotification: [],
  },
  getters: {
    notifications: state => state.notifications,
    countNotifications: state => state.notifications.length,
    fetchTopMenuNotification: state => state.fetchTopMenuNotification,
    updateTopMenuNotification: state => state.updateTopMenuNotification,
  },
  mutations: {
    setNotification(state, notification) {
      state.notifications.push(notification)
    },
    setNotificationNull(state) {
      state.notifications = []
    },
    setNotificationRemove(state, payload) {
      state.notifications.splice(payload, 1)
    },
    [FETCH_TOP_MENU_NOTIFICATION](state, fetchTopMenuNotification) {
      state.fetchTopMenuNotification = fetchTopMenuNotification
    },
    [UPDATE_TOP_MENU_NOTIFICATION](state, updateTopMenuNotification) {
      state.updateTopMenuNotification = updateTopMenuNotification
    },
  },
  actions: {
    addNotification({ commit }, notification) {
      commit('setNotification', notification)
    },
    removeNotification({ commit }, payload) {
      commit('setNotificationRemove', payload)
    },
    removeAllNotification({ commit }) {
      commit('setNotificationNull')
    },
    async [FETCH_TOP_MENU_NOTIFICATION]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchTopMenuNotification($topMenuNotificationArgs: TopMenuNotificationArgs!){
            fetchTopMenuNotification(topMenuNotificationArgs: $topMenuNotificationArgs) {
              idx
              site
              category
              count
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          topMenuNotificationArgs: {
            site: payload.site,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_TOP_MENU_NOTIFICATION, data.fetchTopMenuNotification)
      console.log('FETCH_TOP_MENU_NOTIFICATION : ', data.fetchTopMenuNotification)
    },
    async [UPDATE_TOP_MENU_NOTIFICATION]({ commit }, payload) {
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateTopMenuNotification($topMenuNotificationArgs: TopMenuNotificationArgs!){
            updateTopMenuNotification(topMenuNotificationArgs: $topMenuNotificationArgs) {
              site
              category
            }
          }
        `,
        variables: {
          topMenuNotificationArgs: {
            site: payload.site,
            category: payload.category,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_TOP_MENU_NOTIFICATION, data.updateTopMenuNotification)
      console.log('UPDATE_TOP_MENU_NOTIFICATION : ', data.updateTopMenuNotification)
    },
  },
}
export default notificationStore
