import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { onError } from 'apollo-link-error'
import store from './store'
// import { OCCUR_ERROR } from './store/error/action'
import router from './router'

const { createApolloClient } = require('vue-cli-plugin-apollo/graphql-client')

// Install the vue plugin
Vue.use(VueApollo)

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP

// ConfigfilesRoot
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:3000/graphql',
  // LocalStorage token
  // tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  link: onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, extensions }) => {
        // TODO action으로 빼야합니다.
        const OCCUR_ERROR = 'test'
        store.dispatch(`errorStore/${OCCUR_ERROR}`, message).then(() => {})
        // 401 Error
        if (extensions?.exception?.response?.statusCode === 401) {
          router.replace('/login')
        }
        console.log(`[GraphQL error]: Message: ${message}`)
      })
    }

    if (networkError) {
      console.log(`[Network error1]: ${networkError}`)
    }
  }),
  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: setContext(async (_, { headers }) => {
  //   const token = localStorage.getItem(AUTH_TOKEN)
  //   return {
  //     headers: {
  //       ...headers,
  //       authorization: `Bearer ${token}` || ''
  //     }
  //   }
  // })

  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  getAuth: () => `Bearer ${store.getters['authStore/token'] || ''}`,

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
}

// Create apollo client
const createdApolloClient = createApolloClient({
  ...defaultOptions,
  // ...options
})
export const { apolloClient } = createdApolloClient
export const { wsClient } = createApolloClient

apolloClient.wsClient = wsClient

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $skipAllQueries: true,
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
    },
  })

  return apolloProvider
}
