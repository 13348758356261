import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import cartStore from '@/store/cart'
import crossStore from '@/store/cross'
import betStore from '@/store/bet'
import depositStore from '@/store/deposit'
import messagesStore from '@/store/messages'
import exchangeStore from '@/store/exchange'
import logStore from '@/store/logs'
import settingsStore from '@/store/settings'
import resultStore from '@/store/result'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import authStore from './auth'
import noticeStore from './notice'
import userStore from './user'
import notificationStore from './notification'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    authStore,
    userStore,
    cartStore,
    crossStore,
    betStore,
    depositStore,
    messagesStore,
    exchangeStore,
    verticalMenu,
    logStore,
    noticeStore,
    settingsStore,
    resultStore,
    notificationStore,
  },
  strict: process.env.DEV,
})
