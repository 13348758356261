import { ADD_CART_ITEM, CLEAR_CART_ITEM, REMOVE_CART_ITEM, REMOVE_CART_ITEM_BYCART } from '@/store/cart/mutation'
import { ADD_CART, UPDATE_CART_ITEM } from '@/store/cart/action'

const cartStore = {
  namespaced: true,
  state: () => ({
    cartItems: [],
    removeCartItem: [],
  }),
  getters: {
    cartItems: state => state.cartItems,
    removeCartItem: state => state.removeCartItem,
  },
  mutations: {
    [ADD_CART_ITEM](state, payload) {
      state.cartItems.push(payload)
    },
    [REMOVE_CART_ITEM](state, payload) {
      state.removeCartItem = payload
      // state.cartItems.splice(state.cartItems.indexOf(payload), 1)
    },
    [REMOVE_CART_ITEM_BYCART](state, payload) {
      state.cartItems.splice(payload, 1)
    },
    [CLEAR_CART_ITEM](state) {
      state.cartItems = []
    },
    [UPDATE_CART_ITEM](state, payload) {
      state.cartItems = payload
    },
  },
  actions: {
    async [UPDATE_CART_ITEM]({ state, commit }, payload) {
      // console.log(payload)

      const updatedItems = state.cartItems.map(item => {
        const matchingPayload = payload.find(p => p.eventId === item.gameId)
        // console.log('matchingPayload:', matchingPayload)

        if (matchingPayload) {
          const oddsValues = Object.values(matchingPayload.odds).find(p => p.name === item.gameType)
          // console.log('oddsValues :: ', oddsValues)

          if (oddsValues) {
            const matchingOdds = Object.values(oddsValues.participants).find(p => p.id === item.oddsId)
            // console.log('matchingOdds :: ', matchingOdds)

            if (matchingOdds) {
              item = { ...item, odds: matchingOdds.value_eu }
            }
          }
        }
        return item
      })
      // console.log('updatedItems : ', updatedItems)
      commit(UPDATE_CART_ITEM, updatedItems)
    },
    async [ADD_CART]({ state, commit }, payload) {
      const cartState = {
        site: payload.site,
        userid: payload.userid,
        nickname: payload.nickname,
        category: payload.category,
        sports: payload.sports,
        gameId: payload.gameId,
        mId: payload.mId,
        gameDate: payload.gameDate,
        leagueName: payload.leagueName,
        homeName: payload.homeName,
        awayName: payload.awayName,
        gameTypeId: payload.gameTypeId,
        gameType: payload.gameType,
        oddsId: payload.oddsId,
        oddsHome: payload.oddsHome,
        oddsDraw: payload.oddsDraw,
        oddsAway: payload.oddsAway,
        odds: payload.odds,
        name: payload.name,
        homeAway: payload.homeAway,
      }

      // 동일한 gameId를 가진 모든 항목 제거
      const matchingIndex = state.cartItems.findIndex(item => item.gameId === cartState.gameId && item.gameType === cartState.gameType)

      if (matchingIndex !== -1) {
        const existingItem = state.cartItems[matchingIndex]

        if (existingItem.oddsId !== cartState.oddsId) {
          // oddsId가 다르면 기존 것을 지우기
          commit('REMOVE_CART_ITEM_BYCART', matchingIndex)
        } else {
          // oddsId가 같으면 해당 객체 삭제 후 return
          commit('REMOVE_CART_ITEM_BYCART', matchingIndex)
          return
        }
      }

      // 같은 그룹에서 같은 배팅타입일 때, home과 away를 토글합니다. e.g. 토트넘vs아스날일때, home에 건것을 away로 변경시 home은 취소, away는 선택
      // if (state.cartItems.find(item => item.gameId === payload.gameId && item.gameType === payload.gameType && item.oddsId !== payload.oddsId)) {
      //   commit(REMOVE_CART_ITEM, cartState)
      //   commit(ADD_CART_ITEM, cartState)
      //   return
      // }
      //
      // // 같은 그룹에서 배팅 타입이 바뀌었을때, 핸디캡과 승무패가 같이 있을 경우, 불가처리합니다.
      // if (state.cartItems.find(item => item.gameId === payload.gameId && item.gameType === 'FullTimeResult' && payload.gameType === 'AsianHandicap'
      //               || item.gameId === payload.gameId && payload.gameType === 'FullTimeResult' && item.gameType === 'AsianHandicap')) {
      //   alert('승무패와 핸디캡은 같이 설정할 수 없습니다.')
      //   return
      // }
      //
      // // 같은 그룹에서 배팅 타입이 바뀌었을때, 핸디캡과 언더오버가 같이 있을 경우, 불가처리합니다.
      // if (state.cartItems.find(item => item.gameId === payload.gameId && item.gameType === 'GoalsUnderOver' && payload.gameType === 'AsianHandicap'
      //               || item.gameId === payload.gameId && payload.gameType === 'GoalsUnderOver' && item.gameType === 'AsianHandicap')) {
      //   alert('언더오버와 핸디캡은 같이 설정할 수 없습니다.')
      //   return
      // }

      // cartItem 정보 추출
      const existingItemIndex = state.cartItems.findIndex(item => item.oddsId === payload.oddsId)
      const existingBonus = state.cartItems.findIndex(item => item.gameType === 'Bonus')

      // Bonus Odds minFold Check
      if (cartState.gameType === 'Bonus') {
        if (state.cartItems.length < Number(cartState.name)) {
          return
        }
      }
      // Game Toggle (동일 oddsId)
      if (existingItemIndex !== -1) {
        const minFoldsIndex = state.cartItems.findIndex(item => item.gameType === 'Bonus')
        const minFoldsName = minFoldsIndex !== -1 ? state.cartItems[minFoldsIndex].name : null
        // console.log('existingBonus : ', existingBonus)
        // console.log('length : ', state.cartItems.length - 1) // 3
        // console.log('minFoldsIndex : ', minFoldsIndex)
        // console.log('minFoldsName : ', minFoldsName)
        // console.log('final : ', state.cartItems.length < Number(minFoldsName))

        // bonus 미충족시 bonus 제거
        if ((existingBonus > -1) && minFoldsName !== null && (state.cartItems.length - 2 < Number(minFoldsName))) {
          commit(REMOVE_CART_ITEM_BYCART, existingBonus)
        }
        commit(REMOVE_CART_ITEM_BYCART, existingItemIndex)
      } else if (existingBonus !== -1) {
        // bonus 1개만 선택가능
        commit(REMOVE_CART_ITEM_BYCART, existingBonus)
      } else {
        commit(ADD_CART_ITEM, cartState)
      }
    },
    async [REMOVE_CART_ITEM]({ state, commit }, payload) {
      // cartItem 정보 추출
      const existingBonus = state.cartItems.findIndex(item => item.gameType === 'Bonus')
      const minFoldsIndex = state.cartItems.findIndex(item => item.gameType === 'Bonus')

      const minFoldsName = minFoldsIndex !== -1 ? state.cartItems[minFoldsIndex].name : null
      // bonus 미충족시 bonus 제거
      if ((existingBonus > -1) && minFoldsName !== null && (state.cartItems.length - 2 < Number(minFoldsName))) {
        commit(REMOVE_CART_ITEM_BYCART, existingBonus)
      }
      state.cartItems.splice(state.cartItems.indexOf(payload), 1)
    },
  },
}

export default cartStore
