import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import 'regenerator-runtime/runtime'

import io from 'socket.io-client'
import { createProvider } from '@/vue-apollo'
import * as dayjs from 'dayjs'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

Vue.filter('formatDate', (value, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!value) { return '' }
  return dayjs(new Date(value)).format(format)
})
Vue.filter('formatNumber', value => {
  if (!value) return ''
  return parseFloat(value).toLocaleString()
})

// const socket = io(process.env.VUE_APP_WS_ENDPOINT)
// Vue.prototype.$socket = socket

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.prototype.$site = process.env.VUE_APP_SITE

new Vue({
  // data: {
  //   $socket: io(),
  // },
  router,
  store,
  apolloProvider: createProvider(),
  render: h => h(App),
}).$mount('#app')
