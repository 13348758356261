import { SET_CROSS_FILTER_LEAGUE_NAME } from '@/store/cross/mutation'

const crossStore = {
  namespaced: true,
  state: () => ({
    crossFilter: {
      leagueName: null,
      sportId: '1',
      searchText: null,
    },
  }),
  getters: {
    crossFilter: state => state.crossFilter,
  },
  mutations: {
    [SET_CROSS_FILTER_LEAGUE_NAME](state, leagueName) {
      state.crossFilter.leagueName = leagueName
    },
  },
  actions: {},
}

export default crossStore
