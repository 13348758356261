import { apolloClient } from '@/vue-apollo'
import { FETCH_USER_CASH, UPDATE_USER_CASH } from '@/store/user/action'
import { } from '@/store/user/mutation'
import gql from 'graphql-tag'

const userStore = {
  namespaced: true,
  state: () => ({
    fetchUserCash: [],
    updateUserCash: [],
  }),
  getters: {
    fetchUserCash: state => state.fetchUserCash[0],
    updateUserCash: state => state.updateUserCash[0],
  },
  mutations: {
    [FETCH_USER_CASH](state, fetchUserCash) {
      console.log('FETCH_USER_CASH commit')
      state.fetchUserCash = fetchUserCash
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData.site === fetchUserCash[0].site && userData.userid === fetchUserCash[0].userid) {
        // console.log('cash : ', fetchUserCash[0].cash)
        // console.log('point : ', fetchUserCash[0].point)
        userData.cash = fetchUserCash[0].cash
        userData.point = fetchUserCash[0].point
        localStorage.setItem('userData', JSON.stringify(userData))
      }
    },
    [UPDATE_USER_CASH](state, updateUserCash) {
      // console.log('UPDATE_USER_CASH commit')
      state.updateUserCash = updateUserCash
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData.site === updateUserCash[0].site && userData.userid === updateUserCash[0].userid) {
        // console.log('cash : ', updateUserCash[0].cash)
        // console.log('point : ', updateUserCash[0].point)
        userData.cash = updateUserCash[0].cash
        userData.point = updateUserCash[0].point
        localStorage.setItem('userData', JSON.stringify(userData))
      }
    },
  },
  actions: {
    async [FETCH_USER_CASH]({ commit }, payload) {
      // console.log('payload ::', payload)
      const { data } = await apolloClient.query({
        query: gql`
            query fetchUserCash($site: String, $userid: String) {
                fetchUserCash(site: $site, userid: $userid) {
                    id
                    site
                    userid
                    username
                    nickname
                    recommendNickname
                    email
                    mobile
                    cash
                    point
                    rank
                    createdAt
                    updatedAt
                }
            }
        `,
        variables: {
          site: payload.site,
          userid: payload.userid,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_USER_CASH, data.fetchUserCash)
      console.log('FETCH_USER_CASH : ', data.fetchUserCash)
    },
    async [UPDATE_USER_CASH]({ commit }, payload) {
      // console.log('payload ::', payload)
      const { data } = await apolloClient.query({
        query: gql`
                query fetchUserCash($site: String, $userid: String) {
                      fetchUserCash(site: $site, userid: $userid) {
                          id
                          site
                          userid
                          username
                          nickname
                          recommendNickname
                          email
                          mobile
                          cash
                          point
                          rank
                          createdAt
                          updatedAt
                      }
                  }
              `,
        variables: {
          site: payload.site,
          userid: payload.userid,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_USER_CASH, data.fetchUserCash)
      console.log('FETCH_USER_CASH : ', data.fetchUserCash)
    },
  },
}

export default userStore
