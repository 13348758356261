<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
import { CHECK_EXPIRED_JWT, LOGIN } from '@/store/auth/action'
import { createNamespacedHelpers } from 'vuex'
import Vue from 'vue'
import io from 'socket.io-client'
import Swal from 'sweetalert2'

const authStore = createNamespacedHelpers('authStore')

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  data() {
    return {
      socket: null,
      // audio: null, // audio 객체를 저장할 변수
      // isPlaying: false, // 음악이 재생 중인지 여부를 나타내는 변수
      // playCount: 0, // 추가: 재생 횟수를 기록하는 변수
      // maxPlayCount: 1, // 추가: 최대 재생 횟수
    }
  },
  created() {
    if (store.getters['authStore/token'] && localStorage.getItem('userData')) {
      this.connectToSocket()
    }
    watch(
      () => store.getters['authStore/isAuthenticated'],
      res => {
        if (res) {
          console.log('res ::', res)
          this.connectToSocket()
          // this.socketListener()
        } else {
          console.log('res ::', res)
          this.disconnectFromSocket()
        }
      },
    )
    if (store.getters['authStore/passAuth']) {
      this.connectToSocketGuest()
    }
    watch(
      () => store.getters['authStore/passAuth'],
      res => {
        if (res) {
          console.log('res ::', res)
          this.connectToSocketGuest()
        } else {
          console.log('res ::', res)
          this.disconnectFromSocket()
        }
      },
    )
    if (store.getters['authStore/token'] && localStorage.getItem('userData')) {
      // this.autoplayVideoSettingFirst()
    }
  },
  mounted() {
    setInterval(this.checkExpiredJwt, 1000)
    // this.$socket.emit('enterRoom', '')
    // Socket 리스너 설정
  },
  methods: {
    ...authStore.mapActions({
      checkExpiredJwt: CHECK_EXPIRED_JWT,
    }),
    connectToSocket() {
      this.socket = io(process.env.VUE_APP_WS_ENDPOINT, {
        query: {
          // token: store.getters['authStore/token'],
          connectSite: this.$site,
          site: JSON.parse(localStorage.getItem('userData')).site,
          role: JSON.parse(localStorage.getItem('userData')).role,
          userid: JSON.parse(localStorage.getItem('userData')).userid,
        },
      })
      Vue.prototype.$socket = this.socket

      this.socket.on('connect', () => {
        console.log('Server Connected')
        const roomParam = { userType: 'user', site: JSON.parse(localStorage.getItem('userData')).site }
        this.socket.emit('enterRoom', roomParam)
      })
    },
    connectToSocketGuest() {
      this.socket = io(process.env.VUE_APP_WS_ENDPOINT, {
        query: {
          // token: store.getters['authStore/token'],
          connectSite: this.$site,
          site: this.$site,
          role: 'guest',
          userid: 'guest',
        },
      })
      Vue.prototype.$socket = this.socket

      this.socket.on('connect', () => {
        console.log('Server Connected (Guest)')
        const roomParam = { userType: 'guest', site: this.$site }
        this.socket.emit('enterRoom', roomParam)
      })
    },
    disconnectFromSocket() {
      this.socket.disconnect()
      this.socket = null
      console.log('Server Disconnected')
    },
    // autoplayVideoSettingFirst() {
    //   // 오디오 컨텍스트 생성
    //   Swal.fire({
    //     title: '새로고침 되었습니다',
    //     text: '브라우저 정책에 의한 팝업입니다',
    //     icon: 'info',
    //     showCancelButton: false,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: '확인',
    //     background: 'rgb(3,11,37)',
    //     customClass: {
    //       cancelButton: 'btn btn-dark',
    //       confirmButton: 'btn btn-primary',
    //     },
    //   })
    // },
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
}
</script>
