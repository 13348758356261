import { apolloClient } from '@/vue-apollo'
import gql from 'graphql-tag'

import {
  FETCH_MESSAGES_USER_CNT,
  FETCH_MESSAGES_USER_FROM_ADMIN,
  FETCH_MESSAGES_USER_TO_ADMIN,
} from '@/store/messages/action'
import {
  CREATE_MESSAGES,
  UPDATE_MESSAGES_READ,
} from '@/store/messages/mutation'

const messageStore = {
  namespaced: true,
  state: {
    fetchMessagesUserCnt: [],
    fetchMessagesUserFromAdmin: [],
    fetchMessagesUserToAdmin: [],
    updateMessagesRead: [],
  },
  getters: {
    fetchMessagesUserCnt: state => state.fetchMessagesUserCnt,
    fetchMessagesUserFromAdmin: state => state.fetchMessagesUserFromAdmin,
    fetchMessagesUserToAdmin: state => state.fetchMessagesUserToAdmin,
    updateMessagesRead: state => state.updateMessagesRead,
  },
  mutations: {
    [FETCH_MESSAGES_USER_CNT](state, fetchMessagesUserCnt) {
      state.fetchMessagesUserCnt = fetchMessagesUserCnt
    },
    [FETCH_MESSAGES_USER_FROM_ADMIN](state, fetchMessagesUserFromAdmin) {
      state.fetchMessagesUserFromAdmin = fetchMessagesUserFromAdmin
    },
    [FETCH_MESSAGES_USER_TO_ADMIN](state, fetchMessagesUserToAdmin) {
      state.fetchMessagesUserToAdmin = fetchMessagesUserToAdmin
    },
    [UPDATE_MESSAGES_READ](state, updateMessagesRead) {
      state.updateMessagesRead = updateMessagesRead
    },
  },
  actions: {
    async [CREATE_MESSAGES]({ commit }, payload) {
      return apolloClient
        .mutate({
          mutation: gql`
            mutation createManyMessages($data: MessagesInput!) {
              createManyMessages(data: $data)
            }
          `,
          variables: {
            data: {
              messages: {
                ...payload.messages,
                site: payload.site,
                senderId: payload.userid,
                senderIp: payload.ip,
                receivers: ['admin'],
              },
            },
          },
          fetchPolicy: 'no-cache',
        })
        .then(res => {
          const { data } = res
          const rsltMsg = {
            true: {
              status: true,
              statusMsg: `Messages send successfully! ${data.createManyMessages}`,
            },
            false: { status: false, statusMsg: 'Messages send failed' },
          }[data.createManyMessages > 0 ? 'true' : 'false']
          commit('SEND_MESSAGES_STATUS', rsltMsg)
          return rsltMsg
        })
    },
    async [FETCH_MESSAGES_USER_FROM_ADMIN]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchMessagesUserFromAdmin($messagesUserFromAdminArgs: MessagesUserFromAdminArgs!) {
            fetchMessagesUserFromAdmin(messagesUserFromAdminArgs: $messagesUserFromAdminArgs) {
              idx
              mId
              site
              title
              message
              senderId
              senderIp
              receiverId
              receiverIp
              isRead
              readAt
              isDeleted
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          messagesUserFromAdminArgs: { // 'data' 객체 안에 필요한 변수들을 포함
            site: payload.site,
            receiverId: payload.userid,
            periodFrom: payload.periodFrom,
            periodTo: payload.periodTo,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_MESSAGES_USER_FROM_ADMIN, data.fetchMessagesUserFromAdmin)
      console.log('FETCH_MESSAGES_USER_FROM_ADMIN : ', data.fetchMessagesUserFromAdmin)
    },
    async [FETCH_MESSAGES_USER_TO_ADMIN]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchMessagesUserToAdmin($messagesUserToAdminArgs: MessagesUserToAdminArgs!) {
            fetchMessagesUserToAdmin(messagesUserToAdminArgs: $messagesUserToAdminArgs) {
              idx
              mId
              site
              title
              message
              senderId
              senderIp
              receiverId
              receiverIp
              isRead
              readAt
              isDeleted
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          messagesUserToAdminArgs: { // 'data' 객체 안에 필요한 변수들을 포함
            site: payload.site,
            senderId: payload.userid,
            periodFrom: payload.periodFrom,
            periodTo: payload.periodTo,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_MESSAGES_USER_TO_ADMIN, data.fetchMessagesUserToAdmin)
      console.log('FETCH_MESSAGES_USER_TO_ADMIN : ', data.fetchMessagesUserToAdmin)
    },
    async [FETCH_MESSAGES_USER_CNT]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchMessagesUserCnt($site: String, $userid: String, $role: String) {
            fetchMessagesUserCnt(site: $site, userid: $userid, role: $role)
          }
        `,
        variables: {
          site: payload.site,
          userid: payload.userid,
          role: payload.role,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_MESSAGES_USER_CNT, data.fetchMessagesUserCnt)
      console.log('FETCH_MESSAGES_USER_CNT : ', data.fetchMessagesUserCnt)
    },
    async [UPDATE_MESSAGES_READ]({ commit }, payload) {
      console.log('payload ::', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
            mutation updateMessagesRead($messagesUpdateReadArgs: MessagesUpdateReadArgs!) {
              updateMessagesRead(messagesUpdateReadArgs: $messagesUpdateReadArgs)
            }
        `,
        variables: {
          messagesUpdateReadArgs: {
            idx: Number(payload.idx),
            site: payload.site,
            receiverIp: payload.receiverIp,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_MESSAGES_READ, data.updateMessagesRead)
      console.log('UPDATE_MESSAGES_READ : ', data.updateMessagesRead)
    },
  },
}

export default messageStore
