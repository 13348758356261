import { apolloClient } from '@/vue-apollo'
import { FETCH_DEPOSIT, FETCH_DEPOSIT_ACCOUNT } from '@/store/deposit/action'
import { INSERT_DEPOSIT, UPSERT_DEPOSIT } from '@/store/deposit/mutation'
import gql from 'graphql-tag'

const depositStore = {
  namespaced: true,
  state: () => ({
    fetchDeposit: [],
    fetchDepositAccount: {},
    upsertDeposit: [],
    insertDeposit: [],
  }),
  getters: {
    fetchDeposit: state => state.fetchDeposit,
    fetchDepositAccount: state => state.fetchDepositAccount,
    upsertDeposit: state => state.upsertDeposit,
    insertDeposit: state => state.insertDeposit,
  },
  mutations: {
    [FETCH_DEPOSIT](state, fetchDeposit) {
      state.fetchDeposit = fetchDeposit
    },
    [FETCH_DEPOSIT_ACCOUNT](state, depositAccount) {
      state.fetchDepositAccount = depositAccount
    },
    [UPSERT_DEPOSIT](state, upsertDeposit) {
      state.upsertDeposit = upsertDeposit
    },
    [INSERT_DEPOSIT](state, insertDeposit) {
      state.insertDeposit = insertDeposit
    },
  },
  actions: {
    /* [USER] Deposit > Deposit 내역조회 */
    async [FETCH_DEPOSIT]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
            query fetchDeposit($site: String, $userid: String) {
              fetchDeposit(site: $site, userid: $userid) {
                idx
                site
                userid
                type
                deposit
                userBank
                userAccount
                adminBank
                adminAccount
                uid
                createdAt
                updatedAt
              }
            }
        `,
        variables: {
          site: payload.site,
          userid: payload.userid,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_DEPOSIT, data.fetchDeposit)
      // console.log('FETCH_DEPOSIT : ', data.fetchDeposit)
    },

    /* [USER] Deposit > 계좌발급 */
    async [FETCH_DEPOSIT_ACCOUNT]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
                query fetchDepositAccount($site: String, $userid: String) {
                  fetchDepositAccount(
                    site: $site
                    userid: $userid
                  ) {
                    site
                    userid
                    type
                    accountBank
                    accountNumber
                    accountName
                  }
                }        
              `,
        variables: {
          site: payload.site,
          userid: payload.userid,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_DEPOSIT_ACCOUNT, data.fetchDepositAccount)
      // console.log('FETCH_DEPOSIT_ACCOUNT : ', data.fetchDepositAccount)
    },

    async [INSERT_DEPOSIT]({ commit, state }, payload) {
      console.log('update payload : ', payload)
      const pId = `D00${Date.now()}`
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation insertDeposit($insertDepositArgs: InsertDepositArgs!){
            insertDeposit(insertDepositArgs: $insertDepositArgs) {
              site
              userid
              deposit
              type
              depositType
              userBank
              userAccount
              adminBank
              adminAccount
              paymentId
              userIp
              uid
            }
          }
        `,
        variables: {
          insertDepositArgs: {
            site: payload.site,
            userid: payload.userid,
            deposit: payload.deposit,
            type: payload.type,
            depositType: payload.depositType,
            userBank: payload.userBank,
            userAccount: payload.userAccount,
            adminBank: payload.adminBank,
            adminAccount: payload.adminAccount,
            paymentId: pId,
            userIp: payload.userIp,
            uid: payload.uid,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(INSERT_DEPOSIT, data.insertDeposit)
      console.log('INSERT_DEPOSIT : ', data.insertDeposit)
    },
    async [UPSERT_DEPOSIT]({ commit, state }, payload) {
      console.log('update payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
                  mutation upsertDeposit($idx: Float, $site: String, $userid: String, $deposit: Float, $type: String, $userBank: String, $userAccount: String, $adminBank: String, $adminAccount: String, $uid: String){
                      upsertDeposit(idx: $idx, site: $site, userid: $userid, deposit: $deposit, type: $type, userBank: $userBank, userAccount: $userAccount, adminBank: $adminBank, adminAccount: $adminAccount, uid: $uid) {
                          idx
                          site
                          userid
                          deposit
                          type
                          userBank
                          userAccount
                        adminBank
                        adminAccount
                        uid
                      }
                  }
              `,
        variables: {
          idx: payload.idx,
          site: payload.site,
          userid: payload.userid,
          deposit: payload.deposit,
          type: payload.type,
          userBank: payload.userBank,
          userAccount: payload.userAccount,
          adminBank: payload.adminBank,
          adminAccount: payload.adminAccount,
          uid: payload.uid,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPSERT_DEPOSIT, data.upsertDeposit)
      console.log('UPSERT_DEPOSIT : ', data.upsertDeposit)
    },
  },
}

export default depositStore
