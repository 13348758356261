import gql from 'graphql-tag'
import { apolloClient } from '@/vue-apollo'
import { BET, FETCH_USER_BET_HISTORY } from '@/store/bet/action'
import { ADD_BET_HISTORY } from '@/store/bet/mutation'

const betStore = {
  namespaced: true,

  state: () => ({
    userBetHistory: [],
    betHistories: [],
  }),

  getters: {
    userBetHistory: state => state.userBetHistory,
    betHistories: state => state.betHistories,
  },

  mutations: {
    [FETCH_USER_BET_HISTORY](state, userBetHistory) {
      state.userBetHistory = userBetHistory
    },
    [ADD_BET_HISTORY](state, betHistory) {
      state.betHistories.push(betHistory)
    },
  },
  actions: {
    // async [FETCH_USER_BET_HISTORY]({ state, commit }, payload) {
    //   const { data } = await apolloClient.query({
    //     query: gql`
    //           query fetchUserBetHistory {
    //             fetchUserBetHistory{
    //               idx
    //               userid
    //               groupId
    //               seq
    //               gameId
    //               gameTypeId
    //               oddsId
    //               odds
    //               betCash
    //               createdAt
    //               updatedAt
    //             }
    //         }
    //     `,
    //     variables: {
    //     },
    //     fetchPolicy: 'no-cache',
    //   })
    //   commit(FETCH_USER_BET_HISTORY, data.fetchUserBetHistory)
    // },

    async [BET]({ state, commit }, payload) {
      const gid = `G00${Date.now()}`
      const list = []
      for (let i = 0; i < payload.cartItems.length; i++) {
        list.push({
          site: payload.cartItems[i].site,
          userid: payload.cartItems[i].userid,
          nickname: payload.cartItems[i].nickname,
          category: payload.cartItems[i].category,
          sports: payload.cartItems[i].sports,
          gameId: payload.cartItems[i].gameId,
          mId: payload.cartItems[i].mId,
          gameDate: payload.cartItems[i].gameDate,
          leagueName: payload.cartItems[i].leagueName,
          homeName: payload.cartItems[i].homeName,
          awayName: payload.cartItems[i].awayName,
          oddsType: payload.cartItems[i].gameType,
          oddsId: payload.cartItems[i].oddsId,
          oddsHome: Number(payload.cartItems[i].oddsHome),
          oddsDraw: Number(payload.cartItems[i].oddsDraw),
          oddsAway: Number(payload.cartItems[i].oddsAway),
          odds: Number(payload.cartItems[i].odds),
          handicap: payload.cartItems[i].name, // handicap, under/over
          homeAway: payload.cartItems[i].homeAway,
          betCash: payload.betCash,
          betIp: payload.betIp,
          groupId: gid,
          seq: i,
        })
      }
      console.log('list : ', list)

      const { data } = await apolloClient.mutate({
        mutation: gql`
            mutation bet($userBetHistoryCreateManyInput: [UserBetHistoryCreateManyInput!]!){
                bet(userBetHistoryCreateManyInput: $userBetHistoryCreateManyInput) {
                    site
                    userid
                    nickname
                    sports
                    category
                    gameId
                    mId
                    oddsType
                    oddsId
                    oddsHome
                    oddsDraw
                    oddsAway
                    odds
                    homeAway
                    betCash
                    betIp
                    groupId
                    seq
                }
            }`,
        variables: {
          userBetHistoryCreateManyInput: list,
        },
      })
      commit(ADD_BET_HISTORY, data.bet)
    },
  },
}

export default betStore
